<template>
网页建设中
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>